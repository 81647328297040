import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import EventsExhibitionsPage from "../../components/services/events-exhibitions/index"
const eventsexhibitionsPage = () => {
  return (
    <Layout>
      <Seo
        title="Services - Events &amp; Exhibitions"
        desc=""
        pathname=""
        keywords=""
      />
      <EventsExhibitionsPage />
    </Layout>
  )
}

export default eventsexhibitionsPage
