import React from "react"
import { Link } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import box from "../../../images/svg/menu.svg"
import Right from "../../../images/svg/left-arrow.svg"
import Left from "../../../images/svg/right-arrows.svg"


const exhibitions = () => {
  return (
    <>
      <section className="exclusive">
        <div className="container">
          <h1 className="page-title website-graphic">EXHIBITIONS</h1>
          <div className="row">
            <div className="col-12 col-lg-4">
              <ul className="service-graphic pt-4">
                <li>Standee</li>
                <li>Umbrellas</li>
                <li>Promotion Table</li>
                <li>Brochure Stand</li>
                <li>Canopy</li>
                <li>Table Standee</li>
              </ul>
            </div>
            <div className="col-12 col-lg-2 d-none d-lg-block"></div>
            <div className="col-12 col-lg-4 d-none d-lg-block">
              <ul className="service-graphic  pt-4">
                <li>Pop-Up Display Stand</li>
                <li>Backdrop Stand</li>
                <li>Tent</li>
                <li>Flags</li>
                <li>Clip on Frames</li>
                <li>Message Stand</li>
              </ul>
            </div>
            <div className="col-12 col-lg-12 text-center page-next py-4">
              <div className="d-flex align-items-center justify-content-center">
              <Link to="/services/advertising-promotional">
                {" "}
                <img src={Right} alt="" className="navigation-left"></img>{" "}
                <span>
                Advertishing &amp; Promotional MIX
                </span>
             
              </Link>
              <img src={box} className="navigation-btn" alt="" ></img>
              <Link to="/services/printing-services">
                {" "}
                <span>
                Printing Services{" "}
                </span>
               
                <img src={Left} alt="" className="navigation-right"></img>     
              </Link>
              </div>
             
            </div>
         
          </div>
        </div>
      </section>
    </>
  )
}

export default exhibitions
