import React from "react"


const eventsservices = () => {
  return (
    <>
      <section className="exclusive">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-4">
              <h1 className="page-title exclusive-graphic">EVENTS</h1>
              <ul className="service-graphic">
                <li>Press meets </li>
                <li>Product launches</li>
                <li>Inaugurations</li>
                <li>Theme Parties</li>
                <li>Fashion Shows</li>
                <li>Dealers meet</li>
                <li>Investors meet</li>
                <li>Birthday / wed</li>
              </ul>
            </div>
            <div className="col-12 col-lg-2 d-none d-lg-block"></div>
            <div className="col-12 col-lg-4 d-none d-lg-block">
              <h1 className="page-title exclusive-graphic">Decors</h1>
              <ul className="service-graphic">
                <li>Stall Fabrications </li>
                <li>Stage Decoration</li>
                <li>Event Furnitures</li>
                <li>Sounds &amp; Lighting</li>
                <li>Corporate Gifts</li>
                <li>Promoters / Emcee</li>
                <li>Trophies, certificates &amp; awards</li>
                <li>Anchor / Host / Compere / RJ / VJ</li>
              </ul>
            </div>
            <div className="col-12 col-lg-12 pt-4">
              <p className="story-text">
                A successful event is backed by an efficient strategy, a great
                team and creative display art. Mass Media Creative’s team is
                well experienced and provides the perfect event organization and
                comprehensive project management along with staging, design,
                production, lighting, sound, decor and technical event planning.
                A perfect event is where innovation, growth and opportunities
                meet. Our professional event managers are skilled and
                experienced to execute all the logical elements of the event. We
                understand the need of short-time setup and we are completely
                equipped with all the necessary accessories.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default eventsservices
