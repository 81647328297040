import React from "react"
import Slider from "./slider"
import Events from "./events"
import Gallery from "./gallery"
import EventDesign from "./event-design"
import Services from "./services"
import Exhibition from "./exhibition"
import Portfolio from "../../portfolio/portfolio"
import Service from "../../home/services"
import Clients from "../../home/clients"

const eventshome = () => {
  return (
    <>
      <Slider />
      <Events />
      <Gallery />
      <EventDesign />
      <Services />
      <Exhibition />
      <Portfolio />
      <Service />
      <Clients />
    </>
  )
}

export default eventshome
