import React from "react"

const eventdesign = () => {
  return (
    <section className="exclusive">
      <div className="container pt-4">
        <div className="row">
          <div className="col-12 col-lg-12">
            <p className="services-digital  text-center">
              Advertising that designs experiences for connecting with people
            </p>
            <p className="story-text">
              A successful event is backed by an efficient strategy, a great
              team and creative display art. Mass Media Creative’s team is well
              experienced and provides the perfect event organization and
              comprehensive project management along with staging, design,
              production, lighting, sound, decor and technical event planning. A
              perfect event is where innovation, growth and opportunities meet.
              Our professional event managers are skilled and experienced to
              execute all the logical elements of the event. We understand the
              need of short-time setup and we are completely equipped with all
              the necessary accessories.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default eventdesign
